import React from "react"
import { Link } from "gatsby"
import { Tab } from "@headlessui/react"
import {
  FlyingBoxes,
  DirectRoute,
  ObjectsPortal,
  ProblemSolver,
  HorizontalLinePinBlue,
  ShipXLogo,
  ReduceCosts,
  CustomerExp,
  ScaleBiz,
  GreenApproach,
} from "../components/svg"
import { Seo, Layout, Section, Grid, Container, Col } from "../components"

function About() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const boxes = [
    {
      headline: "A Green Approach to Logistics",
      paragraph:
        "The planet needs our help. ShipX utilizes our technology platform to optimize existing routes and carrier vehicles for deliveries, whenever possible. Additionally, we invest in carbon offset programs to offset the impact of our use of fossil fuels and other resources.",
      graphic: GreenApproach,
      bgColor: "green",
      headlineColor: "green",
      textColor: "white",
    },
    {
      headline: "A Reliable and Innovative Shipping Service",
      paragraph:
        "Our mission is to offer an efficient, reliable, and cost-effective alternative to the limited number of national delivery carriers that currently dominate the shipping marketplace.",
      graphic: DirectRoute,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      headline: "A Flexible Partner",
      paragraph:
        "We understand the importance of personalized delivery options, which is why we tailor unified solutions that cater to the specific parameters of your shipping volume and time-sensitivity. Our focus is to ensure a seamless and convenient delivery experience for your business and customers alike.",
      graphic: ObjectsPortal,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      headline: "A Problem Solver",
      paragraph:
        "ShipX streamlines the shipping process by integrating high-performing carriers into a reliable and transparent solution, easily managed through our technology platform. Our team of problem-solvers is dedicated to meeting the ever-changing needs of modern shippers.",
      graphic: ProblemSolver,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
  ]

  const benefits = [
    {
      tab: "Better Pricing",
      headline: "Reduce Your Shipping Costs",
      paragraph:
        "We strive to keep your shipping costs low by removing accessorial fees and offering predictable pricing. Our goal is to provide the most cost-efficient and reliable shipping solutions that meet your business needs.",
      graphic: ReduceCosts,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      tab: "Improved Customer Experience",
      headline: "Efficient Communication, Proactive Solutions",
      paragraph:
        "Our goal is to ensure complete satisfaction through effective communication and proactive problem-solving. We keep you informed throughout the entire parcel journey and address any disruptions that may occur. Trust us to handle your shipments, so you can focus on growing your business.",
      graphic: CustomerExp,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
    {
      tab: "Scalable Solutions",
      headline: "Partnering for Success: Growing Together",
      paragraph:
        "Expand your business capabilities with our constantly expanding Vetted Delivery Network™ (VDN). This gives you the peace of mind that as your order volume increases, ShipX is ready to execute a seamless delivery every time.",
      graphic: ScaleBiz,
      bgColor: "white",
      headlineColor: "black",
      textColor: "black",
    },
  ]

  return (
    <Layout>
      <Seo
        title="See How ShipX Can Solve Your Last Mile Delivery Issues"
        description="At ShipX, we believe parcel delivery should be executed faster, more efficiently, and should leverage world-class technology to optimize & track shipments"
        slug="about"
      />
      <Section padding="none">
        <div className="bg-gradient-to-r from-gray-900 via-gray-600 to-gray-900">
          <div className="global-container-md">
            <h1 className="text-center text-accent-light mt-1 mb-6">
              About Us
            </h1>
            <div className="h-auto">
              <FlyingBoxes />
            </div>
          </div>
        </div>
        <Section padding="none" classes="py-24 mx-4 lg:mx-0">
          <Container
            padding="global-md"
            classes="lg:p-24 bg-gradient-to-r from-gray-900/90 via-gray-800/95 to-gray-900/90"
          >
            <div className="mx-auto space-y-12 lg:space-y-24">
              <div className="space-y-6 lg:text-xl text-white">
                <div className="flex justify-center">
                  <ShipXLogo className="w-48 lg:w-64 pb-2 lg:pb-6" />
                </div>
                <p>
                  At ShipX, we are dedicated to providing efficient and reliable
                  middle and final mile delivery solutions for e-commerce
                  shippers.
                </p>
                <p>
                  Our technology-driven approach allows us to optimize and track
                  shipments, ensuring a seamless delivery experience for both
                  shippers and their customers.
                </p>
              </div>
              <div className="bg-gradient-to-r from-gray-900/90 via-gray-800/95 to-gray-900/90 p-6 lg:p-12 text-white">
                <ul className="space-y-6 list-inside max-w-xl mx-auto lg:text-lg">
                  <h2 className="font-semibold text-2xl text-center">
                    The Priorities of ShipX
                  </h2>
                  <div className="flex">
                    <i className="fas fa-check text-accent mt-1 mr-3" />
                    <li>
                      Delivery networks should have built-in redundancy to
                      ensure reliability and continuity.
                    </li>
                  </div>
                  <div className="flex">
                    <i className="fas fa-check text-accent mt-1 mr-3" />
                    <li>
                      The existing delivery infrastructure has potential for
                      better leveraging, which can lead to waste reduction.
                    </li>
                  </div>
                  <div className="flex">
                    <i className="fas fa-check text-accent mt-1 mr-3" />
                    <li>
                      A superior delivery experience should be a priority for
                      both shippers and consignees.
                    </li>
                  </div>
                  <div className="flex">
                    <i className="fas fa-check text-accent mt-1 mr-3" />
                    <li>
                      A delivery partnership should enable the success and
                      growth of businesses, rather than being solely perceived
                      as a cost center.
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </Container>
        </Section>

        <Section padding="none">
          <Container
            bgColor="bg-white"
            padding="global-md"
            classes="space-y-12 py-24"
          >
            <Container padding="none" classes="max-w-xl space-y-6 text-center">
              <h2 className="text-xl lg:text-3xl text-secondary-dark px-4 lg:px-0">
                Better Logistics Solutions for Wholesale, Retail, 3PL, and
                E-Commerce Shippers
              </h2>
              <p className="text-left lg:text-center">
                At ShipX, our mission is to revolutionize the shipping industry
                by addressing common pain points such as uncertainty in service,
                pricing, and technology. We strive to set a new standard in the
                industry by delivering exceptional service and utilizing
                cutting-edge technology to ensure a seamless delivery.
              </p>
            </Container>
            <Container padding="none" classes="pt-8">
              <Tab.Group>
                <Tab.List className="flex p-1 space-x-1 bg-secondary rounded-xl">
                  {benefits.map((benefit, index) => (
                    <Tab
                      key={index}
                      className={({ selected }) =>
                        classNames(
                          "w-full p-2.5 lg:py-2.5 text-sm leading-5 font-semibold text-white rounded-lg",
                          "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-accent ring-white ring-opacity-60",
                          selected
                            ? "bg-accent-light text-secondary-dark shadow"
                            : "text-white hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      {benefit.tab}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  {benefits.map((benefit, index) => (
                    <Tab.Panel key={index}>
                      <Grid
                        padding="none"
                        classes="lg:grid-cols-2 gap-y-12 gap-x-24 pt-16"
                      >
                        <Col classes="space-y-10">
                          <div className="">
                            <benefit.graphic className="w-36 h-36 lg:w-48 lg:h-48 mx-auto" />
                          </div>
                        </Col>
                        <Col classes="space-y-10">
                          <div className="text-left space-y-6">
                            <h3 className="text-secondary-dark text-center lg:text-left text-xl">
                              {benefit.headline}
                            </h3>
                            <p className="">{benefit.paragraph} </p>
                          </div>
                        </Col>
                      </Grid>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </Container>
          </Container>
        </Section>

        <Section padding="sm">
          <Container padding="global-md" classes="space-y-12">
            {boxes.map((box, index) => (
              <div key={index}>
                {index % 2 === 0 ? (
                  <Grid
                    classes={
                      box.bgColor === "white"
                        ? "bg-white lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[550px] lg:h-[400px]"
                        : "bg-accent-greenApproach lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[800px] lg:h-[400px]"
                    }
                  >
                    <Col classes="order-2 lg:order-1 m-auto">
                      <box.graphic className="w-full h-full max-h-60 lg:max-h-64" />
                    </Col>
                    <Col classes="order-1 lg:order-2 space-y-4 m-auto">
                      <h2
                        className={
                          box.headlineColor === "black"
                            ? "text-secondary-dark"
                            : "text-accent-light"
                        }
                      >
                        {box.headline}
                      </h2>
                      <p
                        className={
                          box.textColor === "black"
                            ? "text-secondary-dark font-medium"
                            : "text-white font-medium"
                        }
                      >
                        {box.paragraph}
                      </p>
                    </Col>
                  </Grid>
                ) : (
                  <Grid
                    classes={
                      box.bgColor === "white"
                        ? "bg-white lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[550px] lg:h-[400px]"
                        : "bg-accent-greenApproach lg:grid-cols-2 gap-0 justify-center p-6 lg:p-12 h-[800px] lg:h-[400px]"
                    }
                  >
                    <Col classes="order-1 lg:order-1 space-y-4 m-auto">
                      <h2
                        className={
                          box.headlineColor === "black"
                            ? "text-secondary-dark"
                            : "text-accent-light"
                        }
                      >
                        {box.headline}
                      </h2>
                      <p
                        className={
                          box.textColor === "black"
                            ? "text-secondary-dark font-medium"
                            : "text-white font-medium"
                        }
                      >
                        {box.paragraph}
                      </p>
                    </Col>
                    <Col classes="order-2 lg:order-2 m-auto">
                      <box.graphic className="w-full h-full max-h-60 lg:max-h-64" />
                    </Col>
                  </Grid>
                )}
              </div>
            ))}
          </Container>
        </Section>

        <Section padding="none">
          <Container
            padding="none"
            bgColor="bg-accent-variation"
            classes="pt-24 pb-24 lg:pt-24 lg:pb-0 lg:px-0 text-center space-y-24"
          >
            <p className="text-secondary-dark font-bold max-w-lg mx-auto text-2xl lg:text-3xl px-4">
              Let ShipX create a customized delivery solution for you.
            </p>
            <div>
              <Link
                to="/contact/"
                className="px-10 py-6 text-white font-bold lg:text-3xl sm:text-2xl rounded-full bg-secondary hover:bg-accent hover:text-white transition duration-150 ease-in-out"
              >
                Schedule a meeting
              </Link>
            </div>
            <div className="pl-6 flex justify-end">
              <HorizontalLinePinBlue className="w-full lg:w-1/3 relative lg:bottom-[150px] xl:bottom-[150px] 2xl:bottom-[150px]" />
            </div>
          </Container>
        </Section>
      </Section>
    </Layout>
  )
}

export default About
